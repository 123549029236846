<template>
	<v-menu offset-y left rounded="lg" bottom transition="scale-transition" origin="center center">
		<template v-slot:activator="{ on }">
			<v-list-item-avatar class="mr-2 cursor-pointer" v-on="on">
				<v-img max-width="16" max-height="16" :src="'/img/flags/' + user_language.img"></v-img>
			</v-list-item-avatar>
		</template>
		<v-list class='py-0' dense>
			<v-list-item
					v-for="(item, index) in languages"
					:key="index"
					@click="user_language = item"
					class="pr-8"
			>
				<v-list-item-avatar class="my-0 mr-2">
					<v-img max-width="20" max-height="20" :src="'/img/flags/' + item.img"></v-img>
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-content>{{ item.title }}</v-list-item-content>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
	export default {
		name: "LanguageChanger",
		data() {
			return {
				languages: [
					{
						title: 'Français',
						locale: 'fr',
						img: "fr.png"
					},
					{
						title: 'English',
						locale: 'en',
						img: "gb.png"
					}
				],
				created: true
			}
		},
		computed: {
			user_language: {
				get() {
					return this.$store.getters['profile/user_language']
				},
				set(val) {
					this.$i18n.locale = val.locale
					this.$moment.locale(val.locale)
					this.$store.dispatch('loadRoutes', this.$i18n.locale)
					this.$store.commit("profile/CHANGE_USERLANGUAGE_VAL", val)
				}
			}
		},
		watch: {
			user_language() {
				if (this.created) {
					if (this.$store.getters['auth/contact_id'])
						this.$store.dispatch('loadRoutes', this.$i18n.locale)
					this.created = false
				}
			}
		}
		,
		created() {
			this.$store.commit("profile/CHANGE_USERLANGUAGE_VAL", this.languages.find(e => e.locale.match(window.navigator.language.slice(0, 2))))
		}
	}
</script>